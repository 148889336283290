import {Subject, filter, map} from 'rxjs';
import {
  JSONObject,
  JSONValue,
  NodeInstanceData,
  isJSONObject,
  isJSONValue,
} from '../../../types';
import {
  createInstructionValidator,
  globalVariable,
  isAboutMeCore,
  isInstruction,
  localVariable,
  queryStructure,
  queryStructureForParents,
  storage,
} from '../../../helpers';
import {Registry} from '../../../registry';
import {Instruction} from '../types';
import {BroadcastFunction} from './node.types';
import {Node} from './reactFlow.types';

export const DEAD_END = Symbol('DEAD_END');
export const VALUE_NOT_PRESENT = Symbol('VALUE_NOT_PRESENT');

/**
 * Metadata about the instruction and `about` that triggered the flow.
 * The `about` is converted into multiple useful properties that use a
 * baseball motif since the names are much more concise and intuitive
 * than the camelCased abstract names that were considered.
 */
export type FlowHeaders = {
  /** The instruction that triggered the flow. */
  instruction: Instruction;
  showId: string;
  /**
   * The `about` string that the flow author provided. It can be any valid
   * css selector, as it's used to query the XML `structure`.
   */
  selector: string;
  /**
   * An array of the id css selectors of all the nodes that matched the `about`
   * selector at the time the flow was triggered, whether or not each module
   * triggered the flow.
   * This is provided in case all matching modules need some operation applied.
   * An array is used instead of the original `selector` because 1) it's a common
   * currency usable in `bench` below and some flow nodes, and 2) at the time of designing
   * this type, the XML structure was not mutable, but there may be reason to make some
   * properties mutable in the future. By passing this array to downstream nodes, we give
   * the author the choice of using this stable list or re-running `selector` which may
   * return a different set of ids.
   * `structure` with `selector` in downstream node queries.
   * @example
   * `selector`: "[coreId='someCoreId']" matches two modules.
   * `team`: ["#id1", "#id2"]
   */
  team: string[];
  /**
   * The id selector of the module that triggered this flow. Absent
   * or `null` if it wasn't from a page module but rather a module-like
   * actor such as `Router`
   * */
  batter?: string | null;
  /**
   * `team` minus `batter`. An array of id selectors matching each of the page
   * modules that *could* have triggered this flow but did not. This is useful because
   * so many flows concern "which module triggered this?" and "which modules
   * could have, but didn't?". Especially radio-style toggling among 2+ modules.
   */
  bench: string[];
};

/**
 * The values passed to a Flow Node's converter function.
 *
 * When executing a flow, all information is passed between nodes in a packet
 * style with `headers` and `data` (cf `body` in http) properties, plus other
 * properties defined below.
 */
export type ConverterArgs = {
  /**
   * Information about the instruction that triggered this flow including the
   * instruction itself and ids of the modules that matched the `about` selector.
   */
  headers: FlowHeaders;
  /** Data passed from the upstream node. Similar to an `http` `body` */
  data: Record<string, unknown>;
  /** Queryable XML representation of the module tree */
  structure: XMLDocument;
  /**
   * The Flow Node in question that defines which converter function is to run at
   * this step of the flow plus the identities and values of inputs, outputs, and fields.
   */
  node: Node<NodeInstanceData>;
  /**
   * The function for broadcasting into the instruction system.
   */
  broadcast: BroadcastFunction;
};

type ConverterFn = (
  converterArgs: ConverterArgs
) => void | Record<string, unknown> | typeof DEAD_END;

/**
 * Gets a handler function immediately for `id`. If there is not a handler
 * function for `id` a temporary one resulting in `DEAD_END` is returned.
 * @param id of the function to retrieve.
 * @returns the existing registered function if it exists or a temporary one
 * resulting in `DEAD_END` if it does not.
 */
export function getFunc(id: string): ConverterFn;
/**
 * Gets a handler function for `id` waiting until one is registered if one does
 * not already exist.
 * @param id of the function to retrieve.
 * @returns the function registered for `id`.
 */
export function getFunc(id: string, mode: 'async'): Promise<ConverterFn>;
export function getFunc(
  id: string,
  mode: 'async' | 'sync' = 'sync'
): ConverterFn | Promise<ConverterFn> {
  if (mode === 'sync') {
    return getFuncSync(id);
  }
  return new Promise<ConverterFn>((resolve) => {
    if (funcs[id]) {
      resolve(funcs[id]);
    } else {
      const subscription = funcsBus
        .asObservable()
        .pipe(
          filter((ev) => ev.type === 'register' && ev.detail.key === id),
          map((ev) => funcs[ev.detail.key])
        )
        .subscribe((fn) => {
          resolve(fn);
          subscription.unsubscribe();
        });
    }
  });
}

/**
 * Gets a handler function immediately for `id`. If there is not a handler
 * function for `id` a temporary one resulting in `DEAD_END` is returned.
 * @param id of the function to retrieve.
 * @returns the existing registered function if it exists or a temporary one
 * resulting in `DEAD_END` if it does not.
 */
function getFuncSync(id: string): ConverterFn {
  const fn = funcs[id];
  if (typeof fn !== 'undefined') {
    return fn;
  } else {
    console.error({tag: 'getFunc', msg: '❌ NOT FOUND for', id});
    return () => DEAD_END;
  }
}

const instructionFunction =
  (icon: string): ConverterFn =>
  (args): typeof DEAD_END | {instruction: Instruction} => {
    const {node, data, headers, structure} = args;
    if (!('instruction' in data) || !isInstruction(data.instruction)) {
      return DEAD_END;
    }
    const {meta} = data.instruction;
    const filter = node.data?.fields?.find((x) => x.slug === 'about')?.value;
    const moduleNeedle =
      'about' in meta && typeof meta.about === 'string'
        ? meta.about
        : undefined;

    const result = isAboutMeCore(
      structure,
      filter,
      moduleNeedle,
      node.data?.namespace
    );

    if (result.isMatch) {
      headers.selector = filter ?? '*';
      headers.batter = result.moduleNeedle;
      headers.team = result.selectorMatches;
      headers.bench = headers.batter
        ? headers.team.filter((x) => x !== headers.batter)
        : [];

      logFromListener(icon);
      return {
        instruction: data.instruction,
      };
    }
    return DEAD_END;
  };

const readVariableFunction =
  (kind: 'local' | 'global'): ConverterFn =>
  ({data, headers: {showId}, node}) => {
    const myKey =
      node.data?.fields?.find((x) => x.slug === 'about')?.value ?? data.key;

    if (typeof myKey !== 'string') {
      return DEAD_END;
    }
    const key =
      kind === 'local'
        ? localVariable(showId, myKey)
        : globalVariable(showId, myKey);
    const serialized = storage.getItem(key);
    if (serialized === null) {
      return {value: null};
    } else if (typeof serialized !== 'string') {
      return DEAD_END;
    }
    return {
      value: JSON.parse(serialized),
    };
  };

const logFromListener = (icon: string, ...tail: unknown[]): void => {
  if (storage.getItem('logInstructions') === 'true') {
    console.log(`%c${icon}`, 'font-size: 24px;', ...tail);
  }
};

/**
 * Register event, to be emitted when a new entry is added to `funcs`.
 */
interface FuncsRegisterEvent {
  type: 'register';
  detail: {
    key: string;
  };
}

const stringToListArr = (str: string): string[] =>
  str
    .trim()
    .split(/\s*,\s*/)
    .filter((x) => !!x);

const arrToDedupedListString = (arr: string[]): string => {
  return Array.from(new Set(arr.filter((x) => !!x)))
    .sort()
    .join(',');
};

/** Union of change notifications published on the `funcsBus`. */
type FuncsEvent = FuncsRegisterEvent;

const funcsBus = new Subject<FuncsEvent>();

export const funcs: Record<string, ConverterFn> = {
  'about:extract': ({headers}) => {
    return {
      about: headers.selector,
      batter: headers.batter,
      bench: headers.bench.join(','),
      team: headers.team.join(','),
    };
  },

  'about:parent': ({data, structure}) => {
    const {input} = data;
    return {
      output: queryStructureForParents(structure, `${input}`).join(','),
    };
  },

  'about:remove': ({data, structure}) => {
    const {abouts, toRemove} = data;
    const aboutIds = queryStructure(structure, `${abouts}`);
    const toRemoveIds = queryStructure(structure, `${toRemove}`);
    return {
      output: aboutIds.filter((x) => !toRemoveIds.includes(x)).join(','),
    };
  },

  'about:add': ({data, structure}) => {
    const {abouts, toAdd} = data;
    let selector: string = typeof abouts === 'string' ? abouts : '';
    if (typeof toAdd === 'string' && toAdd.length > 0) {
      selector = `${abouts}, ${toAdd}`;
    }
    return {
      output: queryStructure(structure, selector).join(','),
    };
  },

  'about:toIds': ({data, structure}) => {
    const {abouts} = data;
    if (typeof abouts !== 'string') {
      return DEAD_END;
    }

    return {
      output: queryStructure(structure, abouts).join(','),
    };
  },

  adornString: ({node, data}) => {
    const {input} = data;
    const format = node.data?.fields?.find((x) => x.slug === 'format')?.value;
    if (typeof format !== 'string') {
      return {value: input};
    }
    return {
      value: format.replace(
        /\${(input\d)}/g,
        (_, x): string => `${data[x] ?? ''}`
      ),
    };
  },
  consoleLog: ({node, data, headers}) => {
    const {input} = data;
    const label = node.data?.fields?.find((x) => x.slug === 'label')?.value;
    logFromListener('🪵', `${label || node.id.split('-').shift()}`, {
      input,
      headers,
    });
  },

  disregard: ({node, data}) => {
    const {input} = data;
    logFromListener('🕳', `${node.id.split('-').shift()}`, input);
  },

  destructure: ({data}) => {
    if (typeof data.path !== 'string' || !isJSONValue(data.object)) {
      return DEAD_END;
    }
    // Very naïve computation here, just for the example.
    const object = isJSONObject(data.object) ? data.object : {};
    const {path} = data;
    const steps = path.split('.');
    let nextStep = steps.shift();
    let value: JSONValue = object;
    while (nextStep && value && isJSONObject(value) && nextStep in value) {
      value = value[nextStep];
      nextStep = steps.shift();
    }
    if (steps.length > 0) {
      return DEAD_END;
    } else {
      return {value};
    }
  },

  emitInstruction: ({node, data, broadcast}) => {
    logFromListener('💥📣', data.instructionToEmit);
    if (
      !(
        'instructionToEmit' in data &&
        typeof data.instructionToEmit === 'string'
      )
    ) {
      return DEAD_END;
    }
    const instruction: unknown = JSON.parse(data.instructionToEmit);
    if (isInstruction(instruction)) {
      broadcast(instruction, 'flow');
    } else {
      console.error('Invalid instruction at', node);
    }
  },

  equals: ({data}) => {
    const key = data.a == data.b ? 'aIfEqual' : 'aIfNotEqual';
    return {[key]: data.a};
  },

  mediaQuery: ({data}) => {
    if (typeof window === 'undefined') {
      return DEAD_END;
    }

    const mq = 'mediaQuery' in data ? `${data.mediaQuery}` : '';
    const {matches} = window.matchMedia(mq);
    const key = matches ? 'isMatch' : 'notMatch';

    return {
      [key]: data.input,
      value: matches.toString(),
    };
  },

  staticString: ({data}) => {
    return {value: data.value};
  },

  supplantWithString: ({data}) => {
    return {
      value: data.valueToOutput,
    };
  },

  'list:add': ({data}) => {
    if (typeof data.list !== 'string' || typeof data.toAdd !== 'string') {
      return DEAD_END;
    }
    return {
      output: arrToDedupedListString(
        stringToListArr(`${data.list}, ${data.toAdd}`)
      ),
    };
  },

  'list:contains': ({data}) => {
    if (typeof data.list !== 'string' || typeof data.probe !== 'string') {
      return DEAD_END;
    }
    const probe = data.probe.toLowerCase();
    const list = stringToListArr(data.list.toLowerCase());
    const key = list.includes(probe) ? 'if' : 'else';
    return {[key]: data.list};
  },

  'list:remove': ({data}) => {
    if (typeof data.list !== 'string' || typeof data.toRemove !== 'string') {
      return DEAD_END;
    }
    const list = stringToListArr(data.list);
    const toRemove = stringToListArr(data.toRemove);
    const output = list.filter((x) => !toRemove.includes(x));
    return {
      output: arrToDedupedListString(output),
    };
  },

  keyValueLookup: ({node, data}) => {
    if (typeof data.key !== 'string') {
      return DEAD_END;
    }

    const lookupRaw =
      node.data?.fields?.find((x) => x.slug === 'lookup')?.value ?? '';

    const lookup = lookupRaw
      .split(/(\r?\n)+/)
      .map((x) => x.trim())
      .filter((x) => !!x)
      .map((x) => x.split(/\s*,\s*/));

    for (let i = 0; i < lookup.length; i++) {
      if (lookup[i][0] === data.key) {
        const others = lookup.reduce<string[][]>(
          (a, c, index) => {
            if (index === i) {
              return a;
            }
            a[1].push(c[1] ?? '');
            a[2].push(c[2] ?? '');
            a[3].push(c[3] ?? '');
            return a;
          },
          [[], [], [], []]
        );

        return {
          value1: lookup[i][1],
          value2: lookup[i][2],
          value3: lookup[i][3],
          others1: arrToDedupedListString(others[1]),
          others2: arrToDedupedListString(others[2]),
          others3: arrToDedupedListString(others[3]),
        };
      }
    }
    return {
      error: data.key,
    };
  },

  readVariable: readVariableFunction('global'),

  readLocalVariable: readVariableFunction('local'),

  'Global:variable:on-set': instructionFunction('🕸🧩'),

  'variable:set': ({node, data, broadcast}) => {
    const value = data.value;
    const key =
      node.data?.fields?.find((x) => x.slug === 'key')?.value ?? data.key;

    if (typeof key === 'string' && typeof value === 'string') {
      const keys = stringToListArr(key);
      const assignments = keys.reduce<Record<string, JSONValue>>((a, c) => {
        a[c] = value;
        return a;
      }, {});

      broadcast({type: 'variable:set', meta: assignments}, 'flow');
    } else {
      return DEAD_END;
    }
  },

  'variable:removeAllLocal': ({headers}) => {
    const variablePrefix = localVariable(headers.showId);
    let index = storage.length;

    // Since we must access by index, remove in reverse order.
    while (--index >= 0) {
      const key = storage.key(index);
      if (key && key.startsWith(variablePrefix)) {
        storage.removeItem(key);
      }
    }

    return {
      output: '',
    };
  },

  'Router:on-navigate-done': ({node, data}) => {
    if (!('instruction' in data) || !isInstruction(data.instruction)) {
      return DEAD_END;
    }
    const {meta} = data.instruction;
    const currentPath =
      node.data?.fields?.find((x) => x.slug === 'currentPath')?.value || '*';

    // Deliberately leaving off the isAboutMe check here since isAboutMe currently
    // only checks against IDs and Router:on-navigate-done is not filterable by ids.
    if (currentPath === '*' || currentPath === meta.currentPath) {
      logFromListener('🕸🚕');
      return {
        instruction: data.instruction,
      };
    }
    return DEAD_END;
  },
};

const ICONS: Record<string, string> = {
  AccessCode: '🔑',
  Button: '🚰',
  Chat: '💬',
  Countdown: '⏱',
  Image: '🖼',
  Intercom: '🎙',
  Router: '🚌',
  Video: '📽',
  publish: '🕸',
  subscribe: '💥',
};

Registry.on('register', (c) => {
  const instructionSchemas = c.instructions?.anyOf ?? [];
  const isValidInstruction = c.instructions
    ? createInstructionValidator(c.instructions)
    : isInstruction;

  for (const schema of instructionSchemas) {
    const topic = schema.properties.topic.const;
    const which = schema.properties.which.const;
    const namespace = topic.split(':')[0] ?? 'unknown';
    const icon = `${ICONS[which] ?? which}${ICONS[namespace] ?? namespace}`;

    if (typeof funcs[topic] !== 'undefined') {
      continue;
    } else if (which === 'publish') {
      funcs[topic] = instructionFunction(icon);
    } else if (which === 'subscribe') {
      funcs[topic] = ({data, broadcast}) => {
        logFromListener(icon, data);
        const instruction: Instruction = {
          type: topic,
          meta: data as JSONObject,
        };

        if (isValidInstruction(instruction)) {
          broadcast(instruction, 'flow');
        } else {
          console.warn(
            `
            An instruction was marked invalid, and failed to broadcast.
            Instruction details:
          `,
            instruction
          );
        }
      };
    }
    // Notify if we changed `funcs`
    if (typeof funcs[topic] !== 'undefined') {
      funcsBus.next({type: 'register', detail: {key: topic}});
    }
  }
});
